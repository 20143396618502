import React from 'react'
import './CartItem.css'
import { useShoppingCart } from '../../context/ShoppingCartContext'
import menuItems from '../../utils/products.json'
import img from '../../assets/product1.png'


const CartItem = ({ item, products }) => {

    const { removeFromCart, formatIndianPrice, getItemQuantity, getItemPrice, increaseCartQuantity, decreaseCartQuantity } = useShoppingCart();
    const cartItem = products.find(i => i._id === item.id)
    if (cartItem == null) return null

    const quantity = getItemQuantity(item.id);
    const price = getItemPrice(item.id);

    console.log(item)

    return (
        <div className='CartItem relative'>

            <div onClick={() => removeFromCart(item.id)}
                className='absolute top-[-10px] right-0 text-2xl cursor-pointer'>
                ×
            </div>

            <div className='cartItemImg'>
                <img src={cartItem.imageURLs[0]?.url} alt="" />
            </div>

            <div className='cartItemInfo'>
                <div className='text-[var(--primary)] text-2xl font-heading uppercase'>
                    {cartItem.name}
                </div>
                <div className=''>
                    {cartItem.code}
                </div>

                <div className='font-bold'>
                    INR {formatIndianPrice(price)} /-
                </div>


                <div className='flex gap-0 justify-start items-center mt-2'>

                    <div
                        onClick={() => decreaseCartQuantity(item.id)}
                        className='h-7 w-7 bg-[var(--primary)] text-white flex items-center justify-center rounded-none text-lg cursor-pointer leading-[18px]'
                    >
                        -
                    </div>

                    <div className='px-4 py-[2px]'>
                        {quantity}
                    </div>


                    <div
                        onClick={() => increaseCartQuantity(item.id)}
                        className='h-7 w-7 bg-[var(--primary)] text-white flex items-center justify-center rounded-none text-lg cursor-pointer leading-[18px]'
                    >
                        +
                    </div>

                </div>

            </div>
        </div>
    )
}

export default CartItem