import React, { useEffect } from 'react'
import './policies.css';

const Return = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="privacy-policy-container">
            <div className="policy-section">
                <h1>Refund, Cancellation, and Return Policy</h1>
                <p className="policy-paragraph">
                    This Refund, Cancellation, and Return Policy (&quot;Policy&quot;) governs the terms and conditions under which customers of Moheera Jewels (&quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) may return or seek refunds for products purchased from our website moheerajewels.com. This Policy applies to all categories of Products offered by the Company, including, but not limited to, , jewelry made from lab-grown diamonds, and all other jewelry offered by us (hereinafter collectively referred to as &quot;Products&quot;).
                </p>
                <p className="policy-paragraph">
                    This Policy is binding upon both the customer and the Company and is intended to mitigate the Company’s liability while ensuring a transparent and equitable process for our customers. By purchasing any Product from our website, the customer acknowledges that they have read, understood, and agreed to this Policy in its entirety.
                </p>
            </div>

            <div className="policy-section">
                <h2>1. General Principles of Return and Refund</h2>
                <p className="policy-paragraph">
                    1.1 Scope of Returns: The Company permits the return of Products under the specific conditions detailed in this Policy, provided all eligibility requirements are met. Failure to meet these requirements will result in the denial of the return request. Exceptions are only made if explicitly stated by the Company.
                </p>

                <p className="policy-paragraph">
                    The following items are sold on a final sale basis and are not eligible for returns or refunds:
                </p>
                <ul className="policy-list">
                    <li>Customized, bespoke, or personalized jewelry, including items made to order based on the customer's specifications.</li>
                    <li>Jewelry that has been resized or altered, unless such resizing or alteration was performed by the Company and the item is subsequently found to be defective.</li>
                    <li>Items that have been engraved or modified at the customer’s request.</li>
                    <li>Products shipped internationally are not eligible for return or refund under any circumstances, except in cases of verified Manufacturing Defects.</li>
                </ul>

                <p className="policy-paragraph">
                    1.3 Interpretation: The interpretation of this Policy, including whether a Product qualifies for return or refund, lies solely with the Company, whose decision shall be considered final and binding.
                </p>
            </div>

            <div className="policy-section">
                <h2>2. Conditions for Return Eligibility</h2>
                <p className="policy-paragraph">
                    2.1 All return requests must be initiated within 3 days of the date of delivery to the customer. Requests made beyond this timeframe will not be entertained, and the Product will be considered final sale.
                </p>
                <p className="policy-paragraph">
                    2.2 To be eligible for return, the Product must meet the following conditions:
                </p>
                <ul className="policy-list">
                    <li>The Product must be in an unused, unaltered, and undamaged condition, identical to the state in which it was delivered to the customer.</li>
                    <li>All original packaging, tags, certificates of authenticity, grading reports, and any other documentation provided with the Product must be returned intact.</li>
                </ul>

                <p className='policy-paragraph'>
                    2.3 Products exhibiting signs of wear, damage, tampering, resizing, engraving, or any other modification not performed by the Company will not be accepted for return. Additionally, the original condition of the product must be verified to ensure that no discrepancies or alterations have occurred during the customer’s possession.
                </p>

                <p className="policy-paragraph">
                    2.4 The Company will take additional steps during the inspection process to ensure that any product returned complies strictly with the terms of this Policy. Such inspections may include comprehensive assessments by experts to verify authenticity, quality, and compliance with the terms set forth by the Company.
                </p>
            </div>

            <div className="policy-section">
                <h2>3. Cancellation Policy</h2>

                <p className="policy-paragraph">
                    3.1 Customer Cancellation:
                </p>

                <ul className="policy-list">
                    <li>Customers may request cancellation of an order prior to the shipment of the Product. Once the Product has shipped, cancellation requests will not be accepted, and the order will be subject to the terms of this Policy.</li>
                    <li>To cancel an order, the customer must initiate the cancellation process on the website or communicate their intent via email within 24 hours of placing the order or before shipment, whichever is earlier. The Company will confirm the status of the order and process the cancellation accordingly.</li>
                    <li>In the event of an eligible cancellation, the Company will refund the full amount paid by the customer, minus any applicable cancellation or processing fees. Such fees will be communicated clearly during the cancellation process.</li>
                    <li>Orders for custom-made or personalized Products, including custom-designed jewelry or engraved items, may not be eligible for cancellation once production has commenced. The Company reserves the right to deny cancellation requests for these Products. In such cases, customers will be notified promptly, and any deposits or advance payments made for these Products may be forfeited as a part of the cancellation.</li>
                </ul>

                <p className="policy-paragraph">
                    3.2 Company-Initiated Cancellation: <br />
                    The Company reserves the right to cancel any order at its sole discretion in circumstances such as:
                </p>
                <ul className="policy-list">
                    <li>The Product is out of stock or discontinued.</li>
                    <li>Errors in pricing or listing are identified.</li>
                    <li>Incomplete or inaccurate delivery information is provided by the customer.</li>
                    <li>Fraudulent or suspicious activity is detected.</li>
                </ul>

                <p className='policy-paragraph'>
                    3.3 If the Company cancels an order, the customer will be notified via email or phone, and a full refund will be processed within 7 business days. Additionally, the Company may provide options for customers to choose alternative products or issue store credits if applicable, ensuring the customer retains some flexibility in case of cancellations initiated by the Company.
                </p>
            </div>

            <div className="policy-section">
                <h2>4. Refunds for Cancelled Orders</h2>
                <p className="policy-paragraph">
                    Refunds for cancelled orders will be processed in accordance with this Policy. Refunds will be issued to the original payment method within the specified timeframe. Depending on the method of payment, additional processing time may be required by the customer’s financial institution.
                </p>
            </div>

            <div className="policy-section">
                <h2>5. Return Process</h2>
                <p className="policy-paragraph">
                    5.1 To initiate a return, the customer must submit a formal request through our designated customer support email or return portal. The request must include:
                </p>
                <ul className="policy-list">
                    <li>Proof of purchase (order number, invoice, or receipt).</li>
                    <li>A detailed explanation of the reason for the return.</li>
                    <li>Photographic evidence of the Product’s condition, if applicable.</li>
                </ul>

                <p className="policy-paragraph">
                    5.2 Upon receipt of the return request, the Company will review the submission to determine whether the Product qualifies for return. The Company may request additional information or evidence before making a final decision. Approval or denial of the return request will be communicated within 7 business days.
                </p>

                <p className="policy-paragraph">
                    5.3 Once approved, the Company will arrange for pickup and return shipping of the Product. The following conditions apply:
                </p>
                <ul className="policy-list">
                    <li>The Company will coordinate with a reliable and trackable shipping service to ensure safe return.</li>
                    <li>The customer must properly package the Product per Company instructions to prevent damage during transit.</li>
                    <li>The Company will bear the cost of return shipping unless the return is for reasons other than a Manufacturing Defect (such as customer preference, accidental orders, etc.). In such cases, the Company reserves the right to deduct return shipping costs from the refund amount.</li>
                </ul>

                <p className="policy-paragraph">
                    5.4 Customers are encouraged to comply strictly with the packaging requirements to avoid any issues during transit. Failure to properly package the Product may result in damage during return shipping, and the Company will not be liable for any such damages. The condition of the Product upon return must match the conditions stipulated in this Policy, and any discrepancies may affect the customer’s eligibility for a full refund.
                </p>

                <p className="policy-paragraph">
                    5.5 Although the Company will arrange return shipping, the customer is responsible for:
                </p>
                <ul className="policy-list">
                    <li>Securing the Product using all original or equivalent packaging materials to prevent transit damage.</li>
                    <li>Ensuring availability for the shipping company to complete the pick-up within the scheduled timeframe.</li>
                </ul>

                <p className="policy-paragraph">
                    5.6 Upon receipt, the Company will inspect the returned Product to verify whether it meets the eligibility criteria detailed in this Policy. This inspection may take up to 7 business days. For high-value or intricate items, the inspection process may take longer, as additional care and expertise are applied to assess the returned Product&#39;s condition accurately.
                </p>

                <p className="policy-paragraph">
                    5.7 If the returned Product is ineligible for return due to a violation of stated conditions, the customer will be notified, and the Product will be returned at their expense. No refund will be issued in such cases. The Company also reserves the right to charge an administrative fee for handling ineligible returns, and this fee will be communicated to the customer accordingly.
                </p>
            </div>

            <div className="policy-section">
                <h2>6. Refund Policy</h2>
                <p className="policy-paragraph">
                    6.1 If a return is approved after inspection, the refund will be processed within 7 business days. Refunds will be credited to the original payment method used at the time of purchase.
                </p>
                <p className="policy-paragraph">
                    6.2 The refund amount will equal the original purchase price minus any applicable deductions (such as restocking fees, missing documentation, etc.). The Company may also deduct additional costs incurred during the verification and inspection process if discrepancies are found that necessitate further evaluation by third-party experts.
                </p>

                <p className="policy-paragraph">
                    6.3 The Company’s refund and restocking policy varies depending on the type of product:
                </p>
                <ul className="policy-list">
                    <li>If a ready in stock product is returned or canceled, the Company will not charge any fee unless the product is damaged or shows signs of wear. In such cases, the refund will exclude only processing fees and shipping costs.</li>
                    <li>If a made-to-order product is returned or canceled, production fee will be applied, which is a product of making charges upon the product’s metal weight. The fee will vary depending on the p category (e.g., rings, earrings, bracelets).</li>
                </ul>

                <p className="policy-paragraph">
                    This fee will be deducted from the refund amount. The customer will be informed of the exact fees during the return approval process.
                </p>

                <p className="policy-paragraph">
                    6.4 Non-refundable costs include:
                </p>

                <ul className="policy-list">
                    <li>Shipping and handling fees.</li>
                    <li>Costs for customization, engraving, resizing, or alterations made at the customer’s request.</li>
                    <li>Any insurance or shipping costs incurred by the customer during the return process.</li>
                    <li>Any certification and hallmarking charges.</li>
                </ul>

                <p className="policy-paragraph">
                    6.5 The Company emphasizes the importance of transparency in the refund process. Customers are encouraged to carefully review all return conditions to avoid unexpected deductions. While the Company will process refunds promptly, the exact time for funds to reflect in the customer’s account may vary depending on the payment processor or financial institution. Any delays related to third-party financial institutions are beyond the Company’s control.
                </p>
            </div>

            <div className="policy-section">
                <h2>7. Exchanges</h2>

                <p className="policy-paragraph">
                    7.1 The Company may, at its discretion, offer exchanges for eligible Products. The exchange process will follow the same terms as the return process outlined herein. Exchanges must be requested within 3 days from the date of purchase. Any price difference between the original item and the new item will be borne by the customer.
                </p>
                <p className="policy-paragraph">
                    7.2 Custom-made or altered items and other items categorized as non-returnable are not eligible for exchange. Exchanges will only be offered if the requested item is available in stock, and any associated costs, such as additional shipping or price differences, must be covered by the customer.
                </p>
                <p className="policy-paragraph">
                    7.3 For products made in solid gold, The Company may, at its discretion, offer exchanges for the life-time of the product. A restocking fee of 10% of the item value will be levied on the exchange of diamond jewelry and 20% of the item value will be levied on the exchange of uncut diamond or polki jewelry.
                </p>
                <p className="policy-paragraph">
                    7.4 Charges for hallmarking, certification, and grading reports will not be included in the exchange price and will be deducted from the final offer.
                </p>
                <p className="policy-paragraph">
                    7.5 The value of any colored gemstones incorporated into the jewelry will not be included in the exchange price unless specifically agreed upon by the Company at the time of exchange.
                </p>
            </div>

            <div className="policy-section">
                <h2>8. Manufacturing Defects</h2>
                <p className="policy-paragraph">
                    8.1 A "Manufacturing Defect" refers to a fault or flaw arising from the manufacturing process that renders the Product unfit for its intended purpose. Manufacturing defects do not include any issues arising from wear and tear, improper handling, or customer misuse.
                </p>
                <p className="policy-paragraph">
                    8.2 Customers must report any Manufacturing Defects within 3 days of delivery. The Company will arrange for the return of the defective Product and either provide a replacement or issue a refund at its discretion, following verification of the defect. The inspection and verification of the defect may take longer for more intricate pieces, requiring additional time for expert evaluation.
                </p>
                <p className="policy-paragraph">
                    8.3 Normal wear and tear, damage caused by improper handling, misuse, or external factors beyond the Company’s control are not considered Manufacturing Defects and are not eligible for return or refund.
                </p>
            </div>

            <div className="policy-section">
                <h2>9. Miscellaneous</h2>
                <p className='policy-paragraph'>
                    9.1 The Company shall not be held liable for delays, failures, or inability to process returns, cancellations, or refunds due to events beyond its reasonable control, including but not limited to acts of God, natural disasters, government restrictions, war, strikes, pandemics, or any other unforeseen events constituting force majeure.</p>
                <p className='policy-paragraph'>
                    9.2 The Company reserves the right to modify, amend, or update this Policy at any time without prior notice. Any changes will take effect from the date they are posted on the Company’s website. Customers are encouraged.</p>
            </div>
        </div>

    )
}

export default Return