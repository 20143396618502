import React from 'react'
import './BoxEngagement.css'
import Img from '../../../assets/ceg/customring.png'
import icon from '../../../assets/ceg/icon.png'
import { Link } from 'react-router-dom'

const BoxEngagement = () => {
    return (
        <div className='CEG'>

            <div className='HomeCEGImgDiv'>
                <img src={Img} />
            </div>

            <div className='homeCEGContent'>
                <div className='flex flex-col gap-8 sm:gap-[72px] sm:pl-6 items-center'>
                    <div className='font-heading CEGText'>
                        Looking for an engagement ring<br/> made only for you?<br />
                        Begin your <span className='font-heading'> custom design </span><br/> journey today
                    </div>

                    <Link to='/customengagementrings'>
                        <button className='text-sm'>
                            <span>Custom Engagement Rings</span>
                        </button>
                    </Link>
                </div>
            </div>

            {/* <div className='iconCEG'>
                <img src={icon} />
            </div> */}

        </div>
    )
}

export default BoxEngagement