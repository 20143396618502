import React, { useEffect, useState } from 'react'
import { useShoppingCart } from '../../context/ShoppingCartContext';
import Product from './Product'
import axios from 'axios'
import { Skeleton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import pendant from '../../assets/product1.png';
import earring from '../../assets/ear.webp';

const Collection = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All Products');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get("category") || "All Products";
    setSelectedCategory(category);
  }, [location]);


  useEffect(() => {

    const getproducts = async () => {
      try {
        const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getproducts`)

        console.log(data.data);
        setFilteredProducts(data.data);
        setAllProducts(data.data);
      }
      catch (error) { console.log('There was an error getting the products!', error); }
    }

    getproducts();

  }, [])


  useEffect(() => {
    if (selectedCategory === 'All Products') {
      setFilteredProducts(allProducts);  // Show all products
    } else {
      const filtered = allProducts.filter(product => product.category === selectedCategory);
      setFilteredProducts(filtered);  // Show filtered products
      window.scrollTo(0, 0)
    }
  }, [selectedCategory, allProducts]);


  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    navigate(`/collection?category=${category}`);
  };


  const [visibleCount, setVisibleCount] = useState(10);

  const handleLoadMore = () => {
    setVisibleCount(prevCount => prevCount + 10);
  };


  return (

    <>
      <div className='Collection'>

        <div className='flex gap-4 lg:gap-8'>
          <button
            className={selectedCategory === 'All Products' ? 'C_shopBtns mobile selected' : 'C_shopBtns mobile'}
            onClick={() => handleCategoryClick('All Products')}
          >
            Shop all products
          </button>

          {["Rings", "Earrings", "Wristwear", "Neckpieces"].map((category) => (
            <button
              key={category}
              className={`C_shopBtns ${selectedCategory === category ? "selected" : ""}`}
              onClick={() => handleCategoryClick(category)}
            >
              Shop {category}
            </button>
          ))}

        </div>


        <div className='text-[10px] text-white text-left px-4'>
          <div>
            <Link to='/' className='pinkHover'>HOME</Link> / SHOP <span className='uppercase'>{selectedCategory}</span>
          </div>
          <div>{filteredProducts.length} RESULTS</div>
        </div>


        <div className='productCarousel'>

          {filteredProducts.length > 0 && filteredProducts.slice(0, visibleCount).map((item, i) => (
            <Product item={item} key={i} />
          ))}

          {filteredProducts.length == 0 &&
            <>
              <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.800' }} />
              <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.800' }} />
              <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.800' }} />
              <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.800' }} />
            </>
          }

        </div>


        <div className='flex flex-col items-center gap-2 my-8'>
          <div className='font-subheading text-white'>
            showing {Math.min(visibleCount, filteredProducts.length)} out of {filteredProducts.length} items
          </div>

          {visibleCount < filteredProducts.length ? (
            <button className='text-white w-[160px]' onClick={handleLoadMore}>
              Load More
            </button>
          ) : (
            <button className='text-white w-[160px]'>
              No more items to show
            </button>
          )}
        </div>


        <div className='max-w-[1100px] mx-auto w-full'>
          <div className='font-heading text-white text-[48px] text-left'>See More</div>
          <div className='flex gap-4'>
            <div className='bg-white w-full h-[150px] sm:h-[250px] relative p-1' onClick={() => handleCategoryClick('Rings')}>
              <img src={pendant} className='object-cover h-full w-full' />
              <div className='absolute bottom-4 left-0 right-0 seemoreLink'>EXPLORE Rings</div>
            </div>
            <div className='bg-white w-full h-[150px] sm:h-[250px] relative p-1' onClick={() => handleCategoryClick('Earrings')}>
              <img src={earring} className='object-cover h-full w-full' />
              <div className='absolute bottom-4 left-0 right-0 seemoreLink'>EXPLORE earrings</div>
            </div>
          </div>
        </div>

      </div>

      {/* {cartIsOpen ?
        <Cart />
        :
        
      } */}
    </>
  )
}

export default Collection