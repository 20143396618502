import React, { useState } from 'react'
import './collection.css'
import { useShoppingCart } from '../../context/ShoppingCartContext';
import img from '../../assets/product1.png';
import { Link, useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Product = ({ item }) => {

    const { getItemQuantity, formatIndianPrice } = useShoppingCart();

    const quantity = getItemQuantity(item._id);

    const [hover, setHover] = useState(false);

    const location = useLocation();


    return (
        <>
            <div className='min-h-unset md:min-h-[300px] [@media(min-width:780px)]:min-h-[300px]'
                onMouseEnter={e => setHover(true)}
                onMouseLeave={e => setHover(false)}
            >
                <Link to={`/product/${item.code}`} >

                    <div className='ProductDiv'>

                        {/* <div className={item.category == 'Rings' ? 'PD_imgDiv px-4 pt-3 pb-5' : 'PD_imgDiv'}> */}
                        <div className='PD_imgDiv px-1 py-1'>
                            {/* <img src={item.imageURLs[0]?.url} /> */}
                            <LazyLoadImage
                                src={item.imageURLs[0]?.url}
                                effect="blur"
                            />
                        </div>

                        <div className='PD_Content'>
                            <div className='text-[11px]'>
                                {/* {item.code} */}
                            </div>

                            <div className='flex justify-between text-[12px] mt-4'>
                                <div className='pb-[2px]'>{item.name}</div>
                                <div className='flex justify-end gap-1'>
                                    {hover && Object.values(item.customPrices)[0].map((e, index) => (
                                        <div key={index} className='PD_carat'>{e.metal}</div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>

                    {location.pathname == '/' ?
                        null
                        :
                        <div className='PD_desc block md:hidden'>
                            {item.about}
                        </div>
                    }
                </Link>

                {location.pathname == '/' ?
                    null
                    :
                    (!hover ?
                        <div className='PD_desc !hidden md:!block'>
                            {item.about}
                        </div>
                        :

                        <div className='PD_actions !hidden md:!block'>
                            <div className='text-center'>
                                {item.showPrices != false &&
                                    <>INR {formatIndianPrice(Object.values(item.customPrices)[0][0].price)} Onwards</>
                                }
                            </div>

                            {/* {quantity === 0 ? ( */}
                            <Link to={`/product/${item.code}`} >
                                <button
                                    className='PD_actionBtn'
                                >
                                    + Add to Cart
                                </button>
                            </Link>
                        </div>
                    )
                }
            </div>

        </>
    )
}

export default Product