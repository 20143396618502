import React from 'react'
import './Checkout.css'
import { useShoppingCart } from '../../context/ShoppingCartContext'


const CheckoutItem = ({ item, products }) => {

    const { getItemPrice, getItemQuantity, formatIndianPrice, increaseCartQuantity, decreaseCartQuantity } = useShoppingCart();
    const cartItem = products.find(i => i._id === item.id)
    if (cartItem == null) return null

    const quantity = getItemQuantity(item.id);
    const price = getItemPrice(item.id);

    return (
        <div className='CheckoutItem'>
            <div className='checkoutItemImg'>
                <img src={cartItem.imageURLs[0]?.url} alt="" />
            </div>

            <div className='checkoutItemInfo'>
                <div className='font-heading uppercase text-lg'>
                    {cartItem.name}
                </div>
                <div className='text-sm text-black'>
                    {cartItem.code}
                </div>

                <div className='font-bold text-sm'>
                    INR {formatIndianPrice(price)} /-
                </div>


                <div className='flex gap-0 justify-start items-center mt-0'>

                    <div
                        onClick={() => decreaseCartQuantity(item.id)}
                        className='h-7 w-7 bg-[var(--primary)] text-white flex items-center justify-center rounded-none text-lg cursor-pointer leading-[18px]'
                    >
                        -
                    </div>

                    <div className='px-4 py-[2px]'>
                        {quantity}
                    </div>


                    <div
                        onClick={() => increaseCartQuantity(item.id)}
                        className='h-7 w-7 bg-[var(--primary)] text-white flex items-center justify-center rounded-none text-lg cursor-pointer leading-[18px]'
                    >
                        +
                    </div>

                </div>

            </div>
        </div>
    )
}

export default CheckoutItem