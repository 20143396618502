import React, { useEffect, useState } from 'react'
import './ProductDisplay.css'
import { Skeleton } from '@mui/material';
import Product from '../../Collection/Product'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import axios from 'axios';


const ProductDisplay = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [allProducts, setAllProducts] = useState([]);

    // Single state for the selected category
    const [selectedCategory, setSelectedCategory] = useState('All');


    useEffect(() => {

        const getproducts = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getproducts`)

                console.log(data.data);
                setFilteredProducts(data.data);
                setAllProducts(data.data);
            }
            catch (error) { console.log('There was an error getting the products!', error); }
        }

        getproducts();

    }, [])

    // Filter products based on selected category
    useEffect(() => {
        if (selectedCategory === 'All') {
            setFilteredProducts(allProducts);  // Show all products
        } else {
            const filtered = allProducts.filter(product => product.category === selectedCategory);
            setFilteredProducts(filtered);  // Show filtered products
        }
    }, [selectedCategory, allProducts]);


    return (
        <div className={location.pathname == '/' ? 'ProductDisplay' : 'ProductDisplayPortable'}>

            {location.pathname == '/' &&
                <div className='flex gap-4 lg:gap-8 px-0 sm:px-[0px]'>
                    <button
                        className='PD_shopBtns mobile hover:selected'
                        onClick={() => navigate(`/collection`)}
                    >
                        Shop All Products
                    </button>

                    {["Rings", "Earrings", "Wristwear", "Neckpieces"].map((category) => (
                        <button
                            className='PD_shopBtns hover:selected'
                            onClick={() => navigate(`/collection?category=${category}`)}
                        >
                            Shop {category}
                        </button>
                    ))}

                </div>
            }

            {filteredProducts.length > 0 &&
                <Swiper
                    slidesPerView={4}
                    spaceBetween={10}
                    loop={filteredProducts.length > 4}
                    // autoplay={{
                    //     delay: 2500,
                    //     disableOnInteraction: false,
                    //     pauseOnMouseEnter: true
                    // }}
                    speed={1000}
                    // pagination={{
                    //   clickable: true,
                    // }}
                    navigation={true}
                    breakpoints={{
                        250: {
                            slidesPerView: 1,
                            spaceBetween: 5,
                        },
                        370: {
                            slidesPerView: 2,
                            spaceBetween: 10,
                        },
                        800: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                        1000: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                        },
                    }}
                    centeredSlides={false}
                    modules={[Pagination, Navigation, Autoplay]}
                    className="mySwiper"
                >
                    {filteredProducts.length > 0 && filteredProducts.map((item, i) =>

                        <SwiperSlide key={i}>
                            {/* <Link to={`/product/${item.code}`}> */}
                            <Product item={item} />
                            {/* </Link> */}
                        </SwiperSlide>
                    )}

                </Swiper>
            }

            {filteredProducts.length === 0 &&

                <div className='productCarousel'>
                    <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.800' }} />
                    <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.800' }} />
                    <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.800' }} />
                    <Skeleton variant="rectangular" height={200} sx={{ bgcolor: 'grey.800' }} />
                </div>
            }

        </div>
    )
}

export default ProductDisplay