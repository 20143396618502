import React, { useEffect, useState, useRef } from 'react'
import './ProductPage.css'
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import subimg from '../../assets/prodpage.png';
import share from '../../assets/icons/share.png';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import ProductDisplay from '../Home/ProductDisplay/ProductDisplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { Skeleton } from '@mui/material'; // Import Skeleton
import pendant from '../../assets/product1.png';
import earring from '../../assets/ear.webp';
import { Toaster, toast } from 'sonner';


const ProductPage = () => {

    const { id } = useParams();

    const [product, setProduct] = useState()
    const [metalSelected, setMetalSelected] = useState("")
    const [option, setOption] = useState()
    const [shape, setShape] = useState()
    const [price, setPrice] = useState("")
    const [size, setSize] = useState("")


    useEffect(() => {
        window.scrollTo(0, 0)

        const getData = async () => {
            try {
                const data = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/getproductdata?id=${id}`);
                setProduct(data.data);
            }
            catch (err) { console.log(err); }
        }

        getData()
        console.log(product)
    }, [id])

    const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart, formatIndianPrice } = useShoppingCart();

    const quantity = getItemQuantity(product?._id);

    const [showAddedToCartModal, setShowAddedToCartModal] = useState(false);
    console.log(product)

    useEffect(() => {
        if (product && product.showPrices != false) {
            // If both option and metalSelected are defined
            if (option && metalSelected) {
                const selectedPrice = product.customPrices[option]?.find(
                    (item) => item.metal === metalSelected
                );
                console.log(selectedPrice ? selectedPrice.price : 'Price not found');
                setPrice(selectedPrice?.price);
            }

            // If only metalSelected is defined but no option is selected
            else if (!option && metalSelected) {
                const selectedPrice = Object.values(product.customPrices).flat().find(
                    (item) => item.metal === metalSelected
                );
                console.log(selectedPrice ? selectedPrice.price : 'Price not found');
                setPrice(selectedPrice?.price);
            }

            else if (option && !metalSelected) {
                // Get the selected option (e.g., 'HalfBand', 'FullBand') and access its metals
                const selectedOption = product.customPrices[option];
                const firstMetal = selectedOption ? selectedOption[0] : null;  // Get the first metal from the selected option
                console.log(firstMetal ? firstMetal.price : 'Price not found');
                setPrice(firstMetal?.price || ''); // Set price to the first metal's price or an empty string
            }

            // If neither option nor metalSelected is defined
            else if (!option && !metalSelected) {
                const firstOption = Object.values(product.customPrices)[0]; // Get the first option (e.g., HalfBand)
                const firstMetal = firstOption ? firstOption[0] : null;  // Get the first metal from the first option
                console.log(firstMetal ? firstMetal.price : 'Price not found');
                setPrice(firstMetal?.price || ''); // Set price to the first metal's price or an empty string
            }
        }

        if (product && product.showPrices == false) {
            setPrice('Price on Request')
        }
    }, [metalSelected, option, id, product]);

    const handleShare = async () => {
        const currentLink = window.location.href;
        if (navigator.share) {
            // Use the native share option if available
            try {
                await navigator.share({
                    title: "Check this out!",
                    url: currentLink,
                });
            } catch (error) {
                console.error("Error sharing:", error);
            }
        } else {
            // Fallback: Copy link to clipboard
            try {
                await navigator.clipboard.writeText(currentLink);


                alert("Link copied to clipboard!");
            } catch (error) {
                console.error("Failed to copy link:", error);
            }
        }
    };


    const [sizeType, setSizeType] = useState("us"); // Default to US size
    const usSizes = [3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9];
    const indianSizes = Array.from({ length: 16 }, (_, i) => i + 4);

    const [zoomPosition, setZoomPosition] = useState({ show: false, x: 0, y: 0 });
    const [hoveredImage, setHoveredImage] = useState(""); // Store the hovered image
    const zoomBoxRef = useRef(null);

    const handleMouseMove = (e, imgSrc) => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const x = ((e.clientX - left) / width) * 100; // Relative to image width
        const y = ((e.clientY - top) / height) * 100; // Relative to image height

        setHoveredImage(imgSrc); // Set the hovered image
        setZoomPosition({ show: true, x, y });
    };

    const handleMouseLeave = () => {
        setZoomPosition({ show: false, x: 0, y: 0 });
    };

    useEffect(() => {

        console.log("metal:", metalSelected, ", option:", option, ", size:", size, ", shape:", shape)
    }, [metalSelected, option, size, shape])

    const addToCart = (id) => {

        if (metalSelected == '') {
            toast.error('Please select a metal')
            return
        }
        if ((Object.keys(product?.customPrices).length > 1) && option == '') {
            toast.error('Please select an option')
            return
        }
        if ((product?.options.length > 0) && shape == '') {
            toast.error('Please select a shape')
            return
        }
        if ((product.category == 'Rings' || product.category == 'Wristwear') && size == '') {
            toast.error('Please select a size')
            return
        }

        increaseCartQuantity(id, price, metalSelected, option, shape, sizeType, size);
        setShowAddedToCartModal(true)
    }


    return (
        <>
            <div className='ProductPage'>

                <div className='hidden md:flex gap-8 text-sm px-[72px]'>

                    <Link to='/collection'>
                        <button className='C_shopBtns'>
                            shop all products
                        </button>
                    </Link>


                    {["Rings", "Earrings", "Wristwear", "Neckpieces"].map((category) => (
                        <Link to={`/collection?category=${category}`}>
                            <button className='C_shopBtns'>
                                shop {category}
                            </button>
                        </Link>
                    ))}

                </div>

                <div className='text-[10px] text-white text-left px-6 md:px-[88px] uppercase'>
                    <div>
                        <Link to='/' className='pinkHover'>HOME</Link> / <Link to={`/collection?category=${product?.category}`} className='pinkHover'>SHOP {product?.category}</Link> / {product?.name}
                    </div>
                </div>

                {product && <>
                    <div className='productPageGrid'>
                        <div className='PP_imgDiv'>
                            {product?.imageURLs.length > 0 &&
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={0}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={true}
                                    modules={[Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    {product?.imageURLs.map((item, i) =>

                                        <SwiperSlide key={i} className='my-auto h-fit'>
                                            <div
                                                className="group w-full"
                                                onMouseMove={(e) => handleMouseMove(e, item.url)}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <img
                                                    src={item.url}
                                                    alt={`Product ${i}`}
                                                    className="w-full h-auto object-cover md:cursor-zoom-in"
                                                />
                                            </div>
                                        </SwiperSlide>
                                    )}
                                </Swiper>
                            }
                        </div>

                        {/* Zoom Box - Rendered Outside Swiper */}
                        {zoomPosition.show && (
                            <div
                                ref={zoomBoxRef}
                                // className="fixed top-1/2 -translate-y-1/2 left-5 md:left-1/4 lg:left-1/2 right-10 z-50 w-[300px] md:w-full max-w-[500px] h-[300px] md:h-[500px] border-2 border-gray-300 shadow-lg block"
                                className="fixed top-1/2 -translate-y-1/2 left-1/4 lg:left-1/2 right-10 z-50 w-full max-w-[500px] h-[500px] border-2 border-gray-300 shadow-lg hidden md:block"
                                style={{
                                    backgroundImage: `url(${hoveredImage})`,
                                    backgroundSize: "200%", // Adjust the zoom level
                                    backgroundPosition: `${zoomPosition.x}% ${zoomPosition.y}%`, // Center the zoomed image based on mouse position
                                    pointerEvents: "none" // Optional: to make sure the zoom box doesn't interfere with interaction
                                }}
                            ></div>
                        )}

                        <div className='PP_mainInfo'>
                            <div className='flex items-start justify-between gap-2 uppercase'>
                                <div>
                                    <div className='text-[32px] sm:text-[48px] font-heading leading-[28px] sm:leading-[40px] text-[var(--primary)]'>{product?.name}</div>
                                    <div className='mb-4'>{product?.code}</div>
                                    {product.showPrices != false ?
                                        <div className='text-[var(--primary)] text-[18px] sm:text-[22px]'>price in inr {formatIndianPrice(price)}</div>
                                        :
                                        <div className='text-[16px] sm:text-[18px] pinkHover'>Get Price Quotation</div>
                                    }
                                    {product.category == 'Wristwear' &&
                                        <div className='text-[12px] sm:text-[14px] mt-2'>
                                            Price shown for 7-7.5 inches. Final price may differ according to the size ordered.
                                        </div>
                                    }
                                </div>
                                <div
                                    className="p-2 cursor-pointer  transition duration-200 ease-in-out"
                                    onClick={handleShare}
                                ><img src={share} /></div>
                            </div>

                            {Object.keys(product?.customPrices).length > 1 &&
                                <div className='PP_selectFlex'>
                                    <div className='PP_selectLabel'>
                                        {product.category == 'Rings' && 'BAND TYPE'}
                                        {(product.category == 'Earrings' || product.category == 'Neckpieces') && 'WITH/ WITHOUT HALO'}
                                    </div>
                                    <select className='input font-subheading PP_select'
                                        onChange={(e) => setOption(e.target.value)}
                                        value={option}
                                    >
                                        <option value="" disabled selected>
                                            Select an option
                                        </option>
                                        {Object.keys(product?.customPrices).map(e =>
                                            <option value={e}>
                                                {e}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            }

                            <div className='PP_selectFlex'>
                                <div className='PP_selectLabel'>METAL SPECIFICATION</div>
                                <select className='input font-subheading PP_select'
                                    onChange={(e) => setMetalSelected(e.target.value)}
                                    value={metalSelected}
                                >
                                    <option value="" disabled selected>
                                        Select an option
                                    </option>
                                    {Object.values(product?.customPrices)[0].map(e =>
                                        <option value={e.metal}>
                                            {e.metal}
                                        </option>
                                    )}
                                </select>
                            </div>

                            {product?.options.length > 0 &&
                                <div className='PP_selectFlex'>
                                    <div className='PP_selectLabel'>SHAPE</div>
                                    <select className='input font-subheading PP_select'
                                        onChange={(e) => setShape(e.target.value)}
                                        value={shape}
                                    >
                                        <option value="" disabled selected>
                                            Select an option
                                        </option>
                                        {product?.options.map(e =>
                                            <option value={e}>
                                                {e}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            }

                            {product.category == 'Rings' &&
                                <>
                                    <div className='flex gap-4'>
                                        <div className='PP_selectFlex'>
                                            <div className='PP_selectLabel'>SIZE TYPE</div>
                                            <select
                                                className='input font-subheading PP_select'
                                                value={sizeType}
                                                onChange={(e) => setSizeType(e.target.value)}
                                            >
                                                <option value="us">US Size</option>
                                                <option value="indian">Indian Size</option>
                                            </select>
                                        </div>

                                        <div className='PP_selectFlex'>
                                            <div className='PP_selectLabel'>SIZE</div>
                                            <select className='input font-subheading PP_select'
                                                value={size}
                                                onChange={(e) => setSize(e.target.value)}
                                            >
                                                <option value="" disabled selected>
                                                    Select
                                                </option>
                                                {sizeType === "us"
                                                    ? usSizes.map(size => <option key={size} value={size}>{size}</option>)
                                                    : indianSizes.map(size => <option key={size} value={size}>{size}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <Link to='/sizeguide' className='w-fit'>
                                        <div className="border-b-2 text-[var(--primary)] w-fit" style={{ borderColor: "var(--primary)" }}>
                                            SIZE GUIDE
                                        </div>
                                    </Link>
                                </>
                            }

                            {product.category == 'Wristwear' &&
                                <>
                                    <div className='PP_selectFlex'>
                                        <div className='PP_selectLabel'>SIZE</div>
                                        <select className='input font-subheading PP_select'
                                            value={size}
                                            onChange={(e) => setSize(e.target.value)}
                                        >
                                            <option value="6.7">6.7</option>
                                            <option value="7.1">7.1</option>
                                            <option value="7.5">7.5</option>
                                            <option value="7.8">7.8</option>
                                            <option value="8.2">8.2</option>
                                            <option value="8.6">8.6</option>
                                            <option value="9.0">9.0</option>
                                        </select>
                                    </div>

                                    <div>
                                        <span className="border-b-2 text-[var(--primary)]" style={{ borderColor: "var(--primary)" }}>
                                            SIZE GUIDE :
                                        </span>
                                        <span className="">
                                            {' '}TOTAL CIRCUMFERENCE IN INCHES
                                        </span>
                                    </div>
                                </>
                            }

                            {product?.showPrices != false &&
                                (quantity === 0 ? (
                                    <button
                                        onClick={() => addToCart(product?._id)}
                                        className='PP_actionBtn'
                                    >
                                        + Add to Cart
                                    </button>
                                ) :
                                    <div className='flex gap-8 items-center PP_mobileWidthDec'>

                                        <div className='flex gap-0 justify-stretch'>

                                            <button
                                                onClick={() => decreaseCartQuantity(product?._id)}
                                                className='bg-white text-[var(--secondary)] border border-white flex items-center text-lg rounded-none h-[36px]'
                                            >
                                                -
                                            </button>

                                            <div className='border border-white text-white px-4 rounded-none h-[36px] flex items-center'>
                                                {quantity}
                                            </div>


                                            <button
                                                onClick={() => increaseCartQuantity(product?._id, price)}
                                                className='bg-white text-[var(--secondary)] border border-white flex items-center text-lg rounded-none h-[36px]'
                                            >
                                                +
                                            </button>

                                        </div>

                                        {/* <div className=''> */}
                                        <button onClick={() => removeFromCart(product?._id)}
                                            className='PP_actionBtn'>
                                            Delete
                                        </button>
                                        {/* </div> */}
                                    </div>
                                )
                            }

                            <div className='font-bodyone PP_mobileWidthDec'>
                                {product?.about}
                            </div>
                        </div>

                    </div>

                    <div className='productPageGrid'>
                        <div className='PP_subInfo'>
                            <div className='text-[var(--primary)]'>PRODUCT INFORMATION</div>
                            <div className='font-bodyone mt-2'>
                                Product Code: {product?.code} <br />
                                Estimated Gold Weight: {product?.goldWeight}g <br />
                                Estimated Diamond Weight: {product?.diamondWeight}cttw <br />
                            </div>

                            <div className='text-[var(--primary)] mt-6'>DIAMOND & METAL INFORMATION</div>
                            <div className='font-bodyone mt-2'>
                                Diamonds<br />
                                1. Diamonds under 1ct: All are EF color with VVS-VS clarity,  IGI/SGL certified and hallmarked after production.<br />
                                2. Diamonds over 1ct: Pre-certified and set according to your design preferences.<br />

                                Metals<br />
                                1. Gold Vermeil: Affordable, hypoallergenic, more durable than gold-plated, lasts long if used mindfully.<br />
                                2. 14kt Gold: Affordable, durable, some might be allergic to the alloys mixed. <br />
                                3. 18kt Gold: Brighter yellow finish, hypoallergenic, everyday wear, more expensive.

                            </div>

                            <div className='font-bodyone mt-6'>
                                For more details or customization options, please schedule a <br /><Link to='/bookanappointment' className='pinkHoverA'>discovery call</Link> or <Link to='/contactus' className='pinkHoverA'>contact us</Link> via WhatsApp. We’re here to help!
                            </div>

                        </div>
                        <div className='PP_subImg'>
                            <Link to='/collection'><img src={subimg} /></Link>
                        </div>
                    </div>
                </>}

                {!product && (
                    <div className="flex flex-row ml-20 items-start justify-start h-[50vh] gap-20 pr-20">
                        <Skeleton variant="rectangular" height={400} className="mt-4 w-[50%]" sx={{ bgcolor: 'grey.800' }} />

                        <div className="flex flex-col items-start justify-start mt-1 gap-0 w-[50%]">
                            <Skeleton variant="text" className='w-full' height={60} sx={{ bgcolor: 'grey.800' }} />
                            <Skeleton variant="text" className='w-full' height={350} sx={{ bgcolor: 'grey.800' }} />
                        </div>
                    </div>

                )}


                <div className='mt-20'>
                    <div className='text-white text-left px-5 sm:px-[72px]'>OTHER PRODUCTS THAT MAY INTEREST YOU</div>
                    <ProductDisplay />
                </div>

                <div className='max-w-[1100px] mx-5 lg:mx-auto w-[calc(100%-40px)] lg:w-full mt-10'>
                    <div className='font-heading text-white text-[48px] text-left'>See More</div>
                    <div className='flex gap-4'>
                        <Link to='/collection?category=Rings' className='w-full'>
                            <div className='bg-white w-full h-[150px] sm:h-[250px] relative p-1'>
                                <img src={pendant} className='object-cover h-full w-full' />
                                <div className='absolute bottom-4 left-0 right-0 seemoreLink'>EXPLORE rings</div>
                            </div>
                        </Link>
                        <Link to='/collection?category=Earrings' className='w-full'>
                            <div className='bg-white w-full h-[150px] sm:h-[250px] relative p-1'>
                                <img src={earring} className='object-cover h-full w-full' />
                                <div className='absolute bottom-4 left-0 right-0 seemoreLink'>EXPLORE earrings</div>
                            </div>
                        </Link>
                    </div>
                </div>

            </div>

            {showAddedToCartModal ?
                <div className='AddedToCartModalContainer'>
                    <div className='AddedToCartModal'>
                        <div className='absolute top-[-12px] right-[6px] text-4xl flex items-center cursor-pointer'
                            onClick={() => setShowAddedToCartModal(false)}
                        >
                            ×
                        </div>

                        <div className='flex justify-center items-center sm:items-end gap-8 flex-col sm:flex-row'>
                            <div>
                                <div className='font-heading text-3xl uppercase'>{product.name}</div>
                                <div className='font-heading uppercase'>{product.code}</div>
                                {/* <div className='font-subheading'>For INR {formatIndianPrice(price)}</div> */}
                            </div>

                            <div className='modal_imgDiv'>
                                <img src={product?.imageURLs[0]?.url} />
                            </div>
                        </div>

                        <div className='text-[var(--secondary)] mt-8'>ADDED TO CART!</div>
                    </div>
                </div>
                : null}

            <Toaster position="top-center" />

        </>
    )
}

export default ProductPage