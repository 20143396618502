import React, { useEffect } from 'react'
import './policies.css';

const Buyback = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return (
        <div className="privacy-policy-container">
            <div className="policy-section">
                <h1>Buy-Back Policy</h1>
                <p className="policy-paragraph">
                    This Buy-Back Policy (&quot;Policy&quot;) governs the terms and conditions under which customers of
                    Moheera Jewels (&quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) may return products purchased from our
                    website moheerajewels.com. This Policy applies to all categories of products offered by the
                    Company, including, but not limited to, jewelry composed of lab-grown diamonds and other
                    jewelry forms (collectively referred to herein as &quot;Products&quot;). The return process is contingent
                    upon the appraisal and verification of the product by the Company, which includes evaluating
                    the product&#39;s condition, authenticity, and compliance with original specifications.
                </p>
                <p className="policy-paragraph">
                    This Policy is binding upon both the customer and the Company, providing a defined framework
                    to mitigate the Company’s liability while facilitating a structured process for returns. By
                    purchasing any Product from our e-commerce platform, the customer acknowledges and
                    accepts this Policy in its entirety.
                </p>
            </div>

            <div className="policy-section">
                <h2>1. Eligibility for Buy-Back</h2>
                <p className="policy-paragraph">
                    1.1 The following conditions must be satisfied for a product to qualify for buy-back:
                </p>
                <ul className="policy-list">
                    <li>The product must have been purchased from our e-commerce platform, accompanied by the original proof of purchase.</li>
                    <li>The product must be in an acceptable condition, which includes being free from significant damage, wear, or alterations, as determined solely by the Company’s inspection and appraisal team.</li>
                    <li>The product must include all original documentation, including certificates of authenticity, grading reports, and receipts.</li>
                    <li>The product must not exhibit signs of tampering, excessive wear, or damage that materially diminishes its value.</li>
                </ul>
                <p className="policy-paragraph">
                    1.2 Certain categories of products are categorically excluded from the buy-back program, including, but not limited to:
                </p>
                <ul className="policy-list">
                    <li>Customized, bespoke, or personalized jewelry, as these items are unique and cannot be resold at their original value.</li>
                    <li>Products that have been resized, engraved, or otherwise altered, given that modifications can compromise the product’s integrity and marketability.</li>
                    <li>Products that are coated in gold, also known as gold vermeil and gold plated jewelry.</li>
                </ul>
                <p className="policy-paragraph">
                    1.3 The Company retains the exclusive right to reject any buy-back request for products that do not meet the specified criteria, including issues related to product condition, authenticity, and compliance with original specifications.
                </p>
            </div>

            <div className="policy-section">
                <h2>2. Appraisal and Valuation Process</h2>
                <p className="policy-paragraph">
                    2.1 All products submitted for buy-back will undergo a rigorous inspection and appraisal process conducted by the Company. The buy-back price will be determined based on an assessment of the product's current market value, considering factors such as:
                </p>
                <ul className="policy-list">
                    <li>The present condition of the product.</li>
                    <li>The prevailing market price of relevant precious metals and gemstones.</li>
                    <li>Depreciation in value due to wear, damage, or fluctuations in market conditions.</li>
                </ul>
                <p className="policy-paragraph">
                    2.2 The Company will conduct an exhaustive inspection of the product, and the final buy-back price will be established at the Company’s discretion. The appraisal process may require up to 14 business days, at which point the customer will be informed of the determined buy-back price.
                </p>
            </div>

            <div className="policy-section">
                <h2>3. Buy-Back Price</h2>
                <p className="policy-paragraph">
                    3.1 The buy-back price offered to the customer will be calculated as a percentage of the
                    original purchase price, subject to prevailing market conditions. The exact percentage
                    offered will depend upon the product&#39;s condition and the type of product. The Company
                    reserves the right to make adjustments to the buy-back percentage based on its
                    assessment of the product.
                </p>
                <p className="policy-paragraph">
                    3.2 The following shall serve as the guideline values under which eligible products may be bought back by the Company:</p>
                <ul className="policy-list">
                    <li>For eligible jewelry items, a restocking fee of 20% of the item value will be levied on the exchange of diamond jewellery and 30% of the item value will be levied on the exchange of uncut diamond or polki jewelry.</li>
                    <li>The diamond/uncut diamond/polki size, clarity, cut, and carat weight, in addition to market fluctuations, will be considered in determining the final valuation.</li>

                    <li><p>Exclusions:</p>
                        <ul className="policy-list">
                            <li>Any making charges incurred during the original purchase will be deducted from the buy-back price.</li>
                            <li>Charges for hallmarking, certification, and grading reports will not be included in the buy-back price and will be deducted from the final offer.</li>
                            <li>The value of any colored gemstones incorporated into the jewelry will not be included in the buy-back price unless specifically agreed upon by the Company at the time of buy-back. If applicable, the Company reserves the right to appraise such stones separately or exclude them from the valuation.</li>
                            <li>If a discount was applied during the original purchase, the discounted amount will be deducted from the total value offered during the buy-back process, applying proportionally to all components of the item (such as to the metal, diamonds, etc.). This ensures that the buy-back price accurately reflects the actual amount paid by the customer.</li>
                        </ul>
                    </li>
                </ul>
                <p className="policy-paragraph">
                    3.3 If a discount was applied during the original purchase, the discounted amount will be deducted from the total value offered during the buy-back process, applying proportionally to all components of the item (such as to the metal, diamonds, etc.). This ensures that the buy-back price accurately reflects the actual amount paid by the customer.
                </p>

                <p className="policy-paragraph">
                    The buy-back price will be communicated to the customer in writing, and the customer must either accept or reject the offer within 5 days of receiving the valuation. If the customer fails to respond within this period, the offer will be deemed withdrawn, and the product will be returned to the customer at their expense.
                </p>
            </div>

            <div className="policy-section">
                <h2>4. Payment of Buy-Back Proceeds</h2>
                <p className="policy-paragraph">
                    Upon acceptance of the buy-back offer by the customer, the Company will process the payment within 7 business days of such acceptance. Payment will be made to the bank account details provided by the customer at the time of placing the buy-back request, unless otherwise agreed in writing between the Company and the customer. All applicable deductions, including processing or administrative fees, will be communicated to the customer prior to the finalization of the buy-back transaction. Possible fees may include inspection fees, handling fees, or administrative charges, ensuring full transparency and avoiding unexpected costs.
                </p>
            </div>

            <div className="policy-section">
                <h2>5. Title and Ownership Transfer</h2>
                <p className="policy-paragraph">
                    Upon acceptance of the buy-back offer and receipt of payment by the customer, the title and ownership of the product will be transferred to the Company. The customer relinquishes any claims or rights to the product once the payment has been processed.
                </p>
            </div>

        </div>


    )
}

export default Buyback